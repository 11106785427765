<template>
    <div class="mt-2 pl-2">
        <div class="vx-row mb-4">
            <div class="vx-col w-full">
                <label class="vs-input--label">E-Mail Signatur</label>
                <editor api-key="qxbzwh7n69tctq7p5bta4qtatjs7c35wi7juwgf2rcpf29lu" :init="initTinyMce"
                        v-model="user.detail.signature"/>
            </div>
        </div>
        <div class="vx-row mb-2">
            <div class="vx-col w-full">
                <vs-button @click="onSave">Speichern</vs-button>
            </div>
        </div>
    </div>
</template>

<script>
    import ApiService from "../../api";
    import Editor from '@tinymce/tinymce-vue'

    export default {
        name: "ProfileTabSignature",
        components: {
            Editor
        },
        props: ['userData'],
        data() {
            return {
                user: null,
                initTinyMce: {
                    height: 400,
                    menubar: true,
                    plugins: "code table"
                }
            }
        },
        created() {
            this.user = JSON.parse(JSON.stringify(this.userData));
        },
        methods: {
            onSave() {
                const payload = {
                    detail: {
                        signature: this.user.detail.signature
                    },
                };
                this.$vs.loading();

                ApiService.put('users/' + this.user.id, payload).then((response) => {
                    this.$vs.loading.close();
                    this.$vs.notify({
                        title: 'Erfolgreich',
                        text: 'Die Signatur wurden erfolgreich gespeichert',
                        iconPack: 'feather',
                        icon: 'icon-alert-circle',
                        color: 'success'
                    })

                }).catch((response) => {
                    this.$vs.loading.close();
                    this.$vs.notify({
                        title: 'Fehler',
                        text: response.message,
                        iconPack: 'feather',
                        icon: 'icon-alert-circle',
                        color: 'danger'
                    })

                });
            }
        }
    }
</script>

<style scoped>

</style>