<template>
    <div id="profil">
        <div class="vx-row" v-if="user">
            <div class="vx-col w-full sm:w-1/3 lg:w-1/4 mb-base">
                <vx-card class="p-0">
                    <div class="flex justify-center mb-4">
                        <gravatar-img :email="user.email" class="rounded-full shadow-md cursor-pointer block" width="90"
                                      height="90"/>
                    </div>
                    <div class="text-center">
                        <h4>{{user.detail.first_name}} {{user.detail.last_name}}</h4>
                        <p class="text-grey">{{user.roles[0].long_name}}</p>
                    </div>
                    <template slot="footer">
                        <vs-divider/>

                        <vs-list>
                            <vs-list-item title="Deine ID">{{user.id}}</vs-list-item>
                            <vs-list-item title="Rolle">{{user.roles[0].long_name}}</vs-list-item>
                            <vs-list-item title="Teams">{{user.teams.length}}</vs-list-item>
                            <vs-list-item title="Letzer Login">{{user.last_login}}</vs-list-item>
                        </vs-list>
                    </template>
                </vx-card>

                <profile-password></profile-password>
            </div>

            <div class="vx-col w-full sm:w-2/3 lg:w-3/4 mb-base">
                <vx-card>
                    <template slot="no-body">
                        <vs-tabs vs-position="left" alignment="fixed">
                            <vs-tab label="Account" vs-icon="extension">
                                <profile-tab-account :userData="user"></profile-tab-account>
                            </vs-tab>
                            <vs-tab label="Einstellungen" vs-icon="store">
                               <profile-tab-settings :userData="user"></profile-tab-settings>
                            </vs-tab>
                            <vs-tab label="Signatur" vs-icon="get_app">
                               <profile-tab-signature :userData="user"></profile-tab-signature>
                            </vs-tab>
                            <vs-tab label="Sonstiges" vs-icon="link">
                             <profile-tab-other :userData="user"></profile-tab-other>
                            </vs-tab>
                        </vs-tabs>
                    </template>
                </vx-card>
            </div>

        </div>
    </div>
</template>


<script>
    import {mapGetters} from 'vuex';
    import Datepicker from '@sum.cumo/vue-datepicker';
    import {de} from '@sum.cumo/vue-datepicker/src/locale';
    import ProfilePassword from "../../components/profile/ProfilePassword";
    import GravatarImg from "vue-gravatar/src/components/GravatarImg";
    import ProfileTabAccount from "../../components/profile/ProfileTabAccount";
    import ApiService from "../../api";
    import ProfileTabSettings from "../../components/profile/ProfileTabSettings";
    import ProfileTabSignature from "../../components/profile/ProfileTabSignature";
    import ProfileTabOther from "../../components/profile/ProfileTabOther";

    export default {
        name: "Profile",
        components: {
            ProfileTabOther,
            ProfileTabSignature,
            ProfileTabSettings,
            ProfileTabAccount,
            GravatarImg,
            ProfilePassword,
            Datepicker
        },
        data() {
            return {
                new_password: null,
                datepicker_lang: de,
                user: null,
            }
        },
        computed: {
            ...mapGetters([
                'userId'
            ])
        },
        mounted() {
            this.$vs.loading();

            ApiService.get('users/' + this.userId).then((response) => {
                this.$vs.loading.close();
                this.user = response.data.result;
            }).catch((response) => {
                this.$vs.loading.close();

                this.$vs.notify({
                    title: 'Fehler',
                    text: response.message,
                    iconPack: 'feather',
                    icon: 'icon-alert-circle',
                    color: 'danger'
                })

            });
        },
        methods: {
            generatePassword() {
                var length = 10,
                    charset = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789",
                    retVal = "";
                for (var i = 0, n = charset.length; i < length; ++i) {
                    retVal += charset.charAt(Math.floor(Math.random() * n));
                }
                return this.new_password = retVal;
            },
        }
    }
</script>

<style lang="scss">
    #profil {
        .vs-tabs--ul {
            .vs-tabs--li {
                .vs-tabs--btn {
                    padding: 16px 16px;
                }
            }
        }
    }
</style>
