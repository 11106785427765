<template>
    <div class="mt-6 pl-2">
    </div>
</template>

<script>
    export default {
        name: "ProfileTabOther"
    }
</script>

<style scoped>

</style>