<template>
    <div class="mt-6 pl-2">
        <div class="vx-row mb-6">
            <div class="vx-col sm:w-1/4 w-full">
                <span>Vor- & Nachname</span>
            </div>
            <div class="vx-col sm:w-1/2 w-full">
                <div class="vx-row">
                    <div class="vx-col sm:w-1/2 w-full">
                        <vs-input class="w-full" v-model="user.detail.first_name" disabled="disabled" placeholder="Vorname" data-vv-as="Vorname" name="first_name" v-validate="'required'" />
                        <span class="text-danger text-sm" v-if="errors.has('first_name')">{{ errors.first('first_name') }}</span>
                    </div>
                    <div class="vx-col sm:w-1/2 w-full">
                        <vs-input class="w-full" v-model="user.detail.last_name" disabled="disabled" placeholder="Nachname" data-vv-as="Nachname" name="last_name" v-validate="'required'" />
                        <span class="text-danger text-sm" v-if="errors.has('last_name')">{{ errors.first('last_name') }}</span>
                    </div>
                </div>
            </div>
        </div>
        <div class="vx-row mb-6">
            <div class="vx-col sm:w-1/4 w-full">
                <span>E-Mail Adresse</span>
            </div>
            <div class="vx-col sm:w-1/2 w-full">
                <vs-input class="w-full"  icon-pack="feather" icon="icon-mail" icon-no-border v-model="user.email"  disabled="disabled" data-vv-as="E-Mail Adresse" name="email" v-validate="'required|email'" />
                <span class="text-danger text-sm" v-if="errors.has('email')">{{ errors.first('email') }}</span>
            </div>
        </div>
        <div class="vx-row mb-6">
            <div class="vx-col sm:w-1/4 w-full">
                <span>Mobilnummer</span>
            </div>
            <div class="vx-col sm:w-1/2 w-full">
                <vs-input class="w-full" icon-pack="feather" icon="icon-smartphone" icon-no-border
                          v-model="user.detail.mobile" disabled="disabled" placeholder="Mobilnummer" />
            </div>
        </div>
        <div class="vx-row mb-6">
            <div class="vx-col sm:w-1/4 w-full">
                <span>Telefon</span>
            </div>
            <div class="vx-col sm:w-1/2 w-full">
                <div class="vx-row">
                    <div class="vx-col sm:w-3/4 w-full">
                        <vs-input class="w-full"  icon-pack="feather" icon="icon-phone" disabled="disabled" icon-no-border v-model="user.detail.phone" placeholder="Telefon" />
                    </div>
                    <div class="vx-col sm:w-1/4 w-full">
                        <vs-input class="w-full" v-model="user.detail.phone_extension" disabled="disabled" placeholder="Durchwahl" />
                    </div>
                </div>
            </div>
        </div>
        <div class="vx-row mt-2">
            <div class="vx-col w-full">
                <span class="text-danger" >
                    <i class="feather icon-alert-circle"></i>
                    Wenn Sie Ihre Daten ändern möchten, wenden Sie sich bitte an die Personalabteilung.
                </span>
            </div>

        </div>

        <div class="vx-row mb-2">
            <div class="vx-col w-full">
               <!-- <vs-button @click="onSave">Speichern</vs-button>-->
            </div>
        </div>

    </div>
</template>

<script>
    import ApiService from "../../api";

    export default {
        name: "ProfileTabAccount",
        props: ['userData'],
        data() {
            return {
                user: null
            }
        },
        created() {
            this.user = JSON.parse(JSON.stringify(this.userData));
        },
        methods: {
            onSave() {
                const payload = {
                    detail: this.user.detail,
                    email: this.user.email
                };

                this.$validator.validate().then(valid => {
                    if (valid) {
                        this.$vs.loading();

                        ApiService.put('users/' + this.user.id, payload).then((response) => {
                            this.$vs.loading.close();
                            this.$vs.notify({
                                title: 'Erfolgreich',
                                text: 'Die Daten wurden erfolgreich gespeichert',
                                iconPack: 'feather',
                                icon: 'icon-alert-circle',
                                color: 'success'
                            })

                        }).catch((response) => {
                            this.$vs.loading.close();
                            this.$vs.notify({
                                title: 'Fehler',
                                text: response.message,
                                iconPack: 'feather',
                                icon: 'icon-alert-circle',
                                color: 'danger'
                            })

                        });
                    }
                });
            }
        }
    }
</script>

<style scoped>

</style>